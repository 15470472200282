import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/common/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { BucketListComponent } from './pages/writings/bucket-list/bucket-list.component';
import { ThoughtsComponent } from './pages/writings/thoughts/thoughts.component';
import { WritingViewComponent } from './pages/writings/writing-view/writing-view.component';
import { WritingsComponent } from './pages/writings/writings.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'resources',
    component: WritingsComponent,
  },
  {
    path: 'resources/archive/:id',
    component: WritingViewComponent,
  },
  {
    path: 'resources/thoughts-that-wander',
    component: ThoughtsComponent,
  },
  {
    path: 'resources/bucket-list',
    component: BucketListComponent,
  },
  {
    path: 'projects',
    component: ProjectsComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
