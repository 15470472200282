import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PostsService } from '../../services/http/posts/posts.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../services/metaInfo';
export interface writingListTemplate {
  id: string;
  year: string;
  slug: string;
  title: string;
  body: string;
}

@Component({
  selector: 'app-writings',
  templateUrl: './writings.component.html',
  styleUrls: ['./writings.component.scss'],
})
export class WritingsComponent implements OnInit {
  constructor(
    private postService: PostsService,
    private titleService: Title,
    private metaService: Meta,
    private _router: Router,
    private _http: HttpService
  ) {
    this.titleService.setTitle('Prajeet Shrestha | Posts');
    metaInfo.postsOG.map((data) => {
      this.metaService.updateTag(data);
    });
  }
  writingLists: writingListTemplate[] = [
    {
      id: '',
      year: '',
      slug: '',
      title: '',
      body: '',
    },
  ];
  isLoading = true;

  yearWiseList = [];
  YearNumberList = [];

  ngOnInit(): void {
    this.isLoading = true;

    this.postService.getAllPosts().subscribe(
      async (res: writingListTemplate[]) => {
        console.log(res);
        this.writingLists = res;
        this.yearWiseList = await this.separate(this.writingLists);
        console.log(this.yearWiseList);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;

        console.error(err);
      }
    );
  }

  async separate(list) {
    let yearList = [];
    let modifiedData = [];
    await list.map((data, index) => {
      if (!yearList.includes(data['year'])) {
        yearList.push(data['year']);
      }
    });
    console.log(yearList);
    await yearList.map((yearData, yearIndex) => {
      console.log(yearData, ' Round', yearIndex);
      list.map((dataList) => {
        console.log(yearData, dataList['year']);
        if (yearData == dataList['year']) {
          console.log('inside');
          if (modifiedData[yearIndex] === undefined || modifiedData[yearIndex] === null) {
            modifiedData[yearIndex] = [];
          }
          modifiedData[yearIndex].push(dataList);
          console.log(modifiedData);
        }
      });
    });
    this.YearNumberList = yearList;
    return modifiedData;
  }

  toWriting(id, hyperLink) {
    if (hyperLink) {
      console.log('is defined', hyperLink);

      window.open(hyperLink, '_blank');
    } else {
      this._router.navigate(['resources/archive', id]);
    }
  }
}
