<div class="res-container">
  <div class="CTAs">
    <div (click)="allPost()" class="allPostsBtn">
      <span class="icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6668 15L12.8418 13.825L9.02513 10L12.8418 6.175L11.6668 5L6.6668 10L11.6668 15Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
      <span class="text">All posts</span>
    </div>
    <div class="next-prev">
      <span (click)="prev()" class="left">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6668 15L12.8418 13.825L9.02513 10L12.8418 6.175L11.6668 5L6.6668 10L11.6668 15Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
      <span (click)="next()" class="right">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.3332 5L7.1582 6.175L10.9749 10L7.1582 13.825L8.3332 15L13.3332 10L8.3332 5Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
    </div>
  </div>

  <div class="skeleton" *ngIf="isLoading">
    <div style="width: 100%">
      <br />
      <app-skeleton [title]="true"></app-skeleton>
      <br />
    </div>
    <div style="width: 100%; height: 150px">
      <app-skeleton [box]="true"></app-skeleton>
    </div>
    <br />
    <div style="width: 100%; height: 100px">
      <app-skeleton [box]="true"></app-skeleton>
    </div>
    <br />

    <div style="width: 100%; height: 200px">
      <app-skeleton [box]="true"></app-skeleton>
    </div>
    <br />
  </div>
  <app-page-not-found *ngIf="contentNotFound"></app-page-not-found>
  <div class="writing-content" [innerHTML]="currentWritings.body" *ngIf="!isLoading && !contentNotFound"></div>
  <!-- ------------------------------------------------- -->

  <!-- ------------------------------------------------- -->
  <div class="CTAs">
    <div (click)="prev()" class="allPostsBtn">
      <span class="icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6668 15L12.8418 13.825L9.02513 10L12.8418 6.175L11.6668 5L6.6668 10L11.6668 15Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
      <span class="text">Previous</span>
    </div>
    <div class="next-top">
      <span (click)="next()" class="next">
        <span class="text">Next</span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.3332 5L7.1582 6.175L10.9749 10L7.1582 13.825L8.3332 15L13.3332 10L8.3332 5Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
      <span (click)="toTop()" class="top">
        <span class="text">Back to top</span>

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 11.6668L6.175 12.8418L10 9.02513L13.825 12.8418L15 11.6668L10 6.6668L5 11.6668Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
    </div>
  </div>

  <!-- <div id="disqus_thread"></div>

  <noscript
    >Please enable JavaScript to view the
    <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript
  > -->
  <!-- <h4 class="reachout">Got any opinions?</h4>
  <div class="p1 reachout">
    <span> Reach me out on </span>
    <span class="icon">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
            d="M14 2.65825C13.4849 2.88692 12.9313 3.04092 12.3503 3.11033C12.9436 2.75508 13.3992 2.19217 13.6132 1.52133C13.0585 1.85033 12.4437 2.0895 11.7892 2.21842C11.2659 1.66017 10.5187 1.31133 9.69267 1.31133C7.83825 1.31133 6.47558 3.0415 6.89442 4.83758C4.508 4.718 2.39167 3.57467 0.97475 1.83692C0.22225 3.12783 0.5845 4.81658 1.86317 5.67175C1.393 5.65658 0.949667 5.52767 0.562917 5.31242C0.531417 6.643 1.48517 7.88783 2.8665 8.16492C2.46225 8.27458 2.0195 8.30025 1.56917 8.21392C1.93433 9.35492 2.99483 10.185 4.2525 10.2083C3.045 11.1551 1.52367 11.578 0 11.3983C1.27108 12.2133 2.78133 12.6887 4.403 12.6887C9.73583 12.6887 12.7487 8.18475 12.5667 4.14517C13.1279 3.73975 13.615 3.234 14 2.65825V2.65825Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0"><rect width="14" height="14" fill="white" /></clipPath>
        </defs>
      </svg>
    </span>
  </div> -->
</div>
