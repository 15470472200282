import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../../services/metaInfo';
@Component({
  selector: 'app-thoughts',
  templateUrl: './thoughts.component.html',
  styleUrls: ['./thoughts.component.scss'],
})
export class ThoughtsComponent implements OnInit {
  constructor(private metaService: Meta, private titleService: Title) {
    this.titleService.setTitle('Thoughts that wander around');
    metaInfo.thoughtsOG.map((data) => {
      this.metaService.updateTag(data);
    });
  }
  disqus_thread() {
    // DON'T EDIT BELOW THIS LINE
    var date: string = new Date().toDateString();
    var d = document,
      s = d.createElement('script');
    s.src = 'https://prajeet.disqus.com/embed.js';
    s.setAttribute('data-timestamp', date);
    (d.head || d.body).appendChild(s);
  }
  ngOnInit(): void {
    // var disqus_config = function () {
    //   this.page.url = window.location.href; // Replace PAGE_URL with your page's canonical URL variable
    //   this.page.identifier = 'thoughts'; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    // };
    // this.disqus_thread();
  }
}
