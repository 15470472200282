export const metaInfo = {
  homeOG: [
    { name: 'title', content: 'Portfolio - Prajeet Shrestha' },
    {
      name: 'description',
      content:
        'I am a digital craftsman & I love to create web applications and explore the idea of limitless innovation through coding.',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.prajeetshrestha.com.np/' },
    { property: 'og:title', content: 'Portfolio - Prajeet Shrestha' },
    {
      property: 'og:description',
      content:
        'I am a digital craftsman & I love to create web applications and explore the idea of limitless innovation through coding.',
    },
    { property: 'og:image', content: 'https://www.prajeetshrestha.com.np/assets/full.png' },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: 'https://www.prajeetshrestha.com.np/' },
    { property: 'twitter:title', content: 'Portfolio - Prajeet Shrestha' },
    {
      property: 'twitter:description',
      content:
        'I am a digital craftsman & I love to create web applications and explore the idea of limitless innovation through coding.',
    },
    { property: 'twitter:image', content: 'https://www.prajeetshrestha.com.np/assets/full.png' },
  ],
  projectsOG: [
    { name: 'title', content: 'Crafts - Prajeet Shrestha' },
    {
      name: 'description',
      content:
        'I craft digital applications along with tools and love to explore the idea of limitless innovation through coding.',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.prajeetshrestha.com.np/projects' },
    { property: 'og:title', content: 'Crafts - Prajeet Shrestha' },
    {
      property: 'og:description',
      content:
        'I craft digital applications along with tools and love to explore the idea of limitless innovation through coding.',
    },
    { property: 'og:image', content: 'https://www.prajeetshrestha.com.np/assets/origami.png' },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: 'https://www.prajeetshrestha.com.np/projects' },
    { property: 'twitter:title', content: 'Crafts - Prajeet Shrestha' },
    {
      property: 'twitter:description',
      content:
        'I craft digital applications along with tools and love to explore the idea of limitless innovation through coding.',
    },
    { property: 'twitter:image', content: 'https://www.prajeetshrestha.com.np/assets/origami.png' },
  ],
  postsOG: [
    { name: 'title', content: 'Posts - Prajeet Shrestha' },
    {
      name: 'description',
      content: 'I love to write and i want to dedicate this section to all of my randomness and posts.',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.prajeetshrestha.com.np/resources' },
    { property: 'og:title', content: 'Posts - Prajeet Shrestha' },
    {
      property: 'og:description',
      content: 'I love to write and I want to dedicate this section to all of my randomness and posts.',
    },
    { property: 'og:image', content: 'https://www.prajeetshrestha.com.np/assets/full.png' },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: 'https://www.prajeetshrestha.com.np/resources' },
    { property: 'twitter:title', content: 'Posts - Prajeet Shrestha' },
    {
      property: 'twitter:description',
      content: 'I love to write and i want to dedicate this section to all of my randomness and posts.',
    },
    { property: 'twitter:image', content: 'https://www.prajeetshrestha.com.np/assets/full.png' },
  ],
  thoughtsOG: [
    { name: 'title', content: 'Thoughts that wander around - Prajeet Shrestha' },
    {
      name: 'description',
      content: 'I love to write and i want to dedicate this section to all of my randomness.',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.prajeetshrestha.com.np/resources/thoughts-that-wander' },
    { property: 'og:title', content: 'Thoughts that wander around - Prajeet Shrestha' },
    {
      property: 'og:description',
      content: 'I love to write and i want to dedicate this section to all of my randomness.',
    },
    { property: 'og:image', content: 'https://www.prajeetshrestha.com.np/assets/flower.png' },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: 'https://www.prajeetshrestha.com.np/resources/thoughts-that-wander' },
    { property: 'twitter:title', content: 'Thoughts that wander around - Prajeet Shrestha' },
    {
      property: 'twitter:description',
      content: 'I love to write and i want to dedicate this section to all of my randomness.',
    },
    { property: 'twitter:image', content: 'https://www.prajeetshrestha.com.np/assets/flower.png' },
  ],
  bucketListOG: [
    { name: 'title', content: 'My list of 100 things - Prajeet Shrestha' },
    {
      name: 'description',
      content:
        'There are so many things I want to do before I lay on my death bed and feel the sense of fulfillment. Let me know, if you have any recommendation. ❤ (last updated 2021)',
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.prajeetshrestha.com.np/resources/thoughts-that-wander' },
    { property: 'og:title', content: 'My list of 100 things - Prajeet Shrestha' },
    {
      property: 'og:description',
      content:
        'There are so many things I want to do before I lay on my death bed and feel the sense of fulfillment. Let me know, if you have any recommendation. ❤ (last updated 2021)',
    },
    { property: 'og:image', content: 'https://www.prajeetshrestha.com.np/assets/half.png' },

    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: 'https://www.prajeetshrestha.com.np/resources/thoughts-that-wander' },
    { property: 'twitter:title', content: 'My list of 100 things - Prajeet Shrestha' },
    {
      property: 'twitter:description',
      content:
        'There are so many things I want to do before I lay on my death bed and feel the sense of fulfillment. Let me know, if you have any recommendation. ❤ (last updated 2021)',
    },
    { property: 'twitter:image', content: 'https://www.prajeetshrestha.com.np/assets/half.png' },
  ],
  dynamicMetaProperty: function (prop, cont) {
    return { property: prop, content: cont };
  },
  dynamicMetaName: function (name, cont) {
    return { name: name, content: cont };
  },
  metaTags: function (title, description, id) {
    return [
      { name: 'title', content: title },
      {
        name: 'description',
        content: description,
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: `https://www.prajeetshrestha.com.np/resources/${id}` },
      { property: 'og:title', content: title },
      {
        property: 'og:description',
        content: description,
      },
      { property: 'og:image', content: '' },

      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: `https://www.prajeetshrestha.com.np/resources/${id}` },
      { property: 'twitter:title', content: title },
      {
        property: 'twitter:description',
        content: description,
      },
      { property: 'twitter:image', content: '' },
    ];
  },
};
