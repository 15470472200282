import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WritingsComponent } from './pages/writings/writings.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { HomeComponent } from './pages/home/home.component';
import { WritingViewComponent } from './pages/writings/writing-view/writing-view.component';
import { ThoughtsComponent } from './pages/writings/thoughts/thoughts.component';
import { BucketListComponent } from './pages/writings/bucket-list/bucket-list.component';
import { SkeletonComponent } from './services/common/skeleton/skeleton.component';
import { PageNotFoundComponent } from './core/common/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    WritingsComponent,
    ProjectsComponent,
    HomeComponent,
    WritingViewComponent,
    ThoughtsComponent,
    BucketListComponent,
    SkeletonComponent,
    PageNotFoundComponent,
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule],
  providers: [Meta],
  bootstrap: [AppComponent],
})
export class AppModule {}
