import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../../services/metaInfo';
@Component({
  selector: 'app-bucket-list',
  templateUrl: './bucket-list.component.html',
  styleUrls: ['./bucket-list.component.scss'],
})
export class BucketListComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('My list of 100 things');
    metaInfo.bucketListOG.map((data) => {
      this.metaService.updateTag(data);
    });
  }
  disqus_thread() {
    // DON'T EDIT BELOW THIS LINE
    var date: string = new Date().toDateString();
    var d = document,
      s = d.createElement('script');
    s.src = 'https://prajeet.disqus.com/embed.js';
    s.setAttribute('data-timestamp', date);
    (d.head || d.body).appendChild(s);
  }
  ngOnInit(): void {
    // var disqus_config = function () {
    //   this.page.url = window.location.href; // Replace PAGE_URL with your page's canonical URL variable
    //   this.page.identifier = 'bucket-list'; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    // };
    // this.disqus_thread();
  }
}
