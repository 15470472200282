import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { PostsService } from 'src/app/services/http/posts/posts.service';
import { writings } from '../../../../assets/articles/articles';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../../services/metaInfo';
export interface writingListTemplate {
  id: string;
  year: string;
  slug: string;
  title: string;
  body: any;
}
@Component({
  selector: 'app-writing-view',
  templateUrl: './writing-view.component.html',
  styleUrls: ['./writing-view.component.scss'],
})
export class WritingViewComponent implements OnInit {
  constructor(
    private _http: HttpService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    private _router: Router,
    private postService: PostsService,
    private route: ActivatedRoute
  ) {}
  currentWritings = {
    id: 'cd4bf891-dc2a-4cbb-8bdf-8443aa6d4cc1',
    slug: '',
    body: null,
  };
  isLoading = true;
  writingLists: writingListTemplate[] = [
    {
      id: '',
      year: '',
      slug: '',
      title: '',
      body: null,
    },
  ];

  state = {
    pos: 0,
    next: {},
    current: {},
    prev: {},
  };

  id;
  test = 'http://localhost:3000';
  prod = 'https://portfolio-prajeet-api.herokuapp.com';
  cUrl = '';
  pageI = '';
  contentNotFound = false;
  // disqus_thread() {
  //   // DON'T EDIT BELOW THIS LINE
  //   var date: string = new Date().toDateString();
  //   var d = document,
  //     s = d.createElement('script');
  //   s.src = 'https://prajeet.disqus.com/embed.js';
  //   s.setAttribute('data-timestamp', date);
  //   (d.head || d.body).appendChild(s);
  // }
  HtmlToPlainText(html: string) {
    const tagWhiteSpace = /[^(>|$)(\W|\n|\r)+<]/g; //matches one or more (white space or line breaks) between '>' and '<'
    const stripFormatting = /<[^>]*(>|$)/g; //match any character between '<' and '>', even when end tag is missing
    const lineBreak = /<(br|BR)\s{0,1}\/{0,1}>/g; //matches: <br>,<br/>,<br />,<BR>,<BR/>,<BR />
    var lineBreakRegex = html.match(stripFormatting);
    let text = html;
    lineBreakRegex.map((data) => {
      text = text.replace(data, '');
    });
    return text.slice(0, 160) + '...';
  }

  ngOnInit(): void {
    let that = this;
    console.log(window.location.href);
    const id = window.location.pathname.split('/')[3];
    this.pageI = id;
    this.isLoading = true;

    this.postService.getOnePost(id).subscribe(
      (res) => {
        console.log(res);
        that.currentWritings.id = res['id'];
        that.currentWritings.slug = res['slug'];
        if (res['sanitize'] == 'TRUE') {
          that.currentWritings.body = this.sanitizer.bypassSecurityTrustHtml(res['body']);
        } else {
          that.currentWritings.body = res['body'];
        }
        console.log(that.HtmlToPlainText(res['body']));
        that.contentNotFound = that.currentWritings.body.length <= 0 ? true : false;
        console.log(that.currentWritings.body);
        that.state.current = that.currentWritings;
        that.titleService.setTitle(res['title']);
        metaInfo.metaTags(res['title'], that.HtmlToPlainText(res['body']), res['id']).map((data) => {
          that.metaService.updateTag(data);
        });
        that.postService.getAllPosts().subscribe(
          async (res: writingListTemplate[]) => {
            console.log(res);
            that.writingLists = res;
            that.writingLists.map((data, index) => {
              if (data.id == that.currentWritings.id) {
                that.state.pos = index;
              }
            });
            this.isLoading = false;

            console.log(this.state);
          },
          (err) => {
            this.isLoading = false;

            console.error(err);
          }
        );
      },
      (err) => {
        this.isLoading = false;

        console.error(err);
      }
    );

    // var disqus_config = function () {
    //   this.page.url = window.location.href; // Replace PAGE_URL with your page's canonical URL variable
    //   this.page.identifier = id; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    // };
    // this.disqus_thread();
  }
  allPost() {
    this._router.navigate(['/resources']);
  }

  toTop() {
    document.getElementsByClassName('bodyMain')[0].scrollTo({ top: 0, behavior: 'smooth' });
  }
  ngAfterContentInit() {
    // this.currentWritings.body = writings.writing1.body;
    // this.currentWritings.slug = writings.writing1.slug;
  }
  readTextFile(file) {
    var rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = function () {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;
          console.log(allText);
        }
      }
    };
    rawFile.send(null);
  }

  prev() {
    console.log(this.writingLists.length);
    console.log(this.state);

    if (this.state.pos > 0) {
      this.state.pos -= 1;
    } else if (this.state.pos == 0) {
      this.state.pos = this.writingLists.length - 1;
    }
    this._router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this._router.navigate(['resources/archive', this.writingLists[this.state.pos].id]));
  }
  next() {
    console.log(this.writingLists.length);
    console.log(this.state);
    if (this.state.pos < this.writingLists.length - 1 && this.state.pos >= 0) {
      this.state.pos += 1;
    } else {
      this.state.pos = 0;
    }
    this._router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this._router.navigate(['resources/archive', this.writingLists[this.state.pos].id]));
  }
}
