<div class="home-container">
  <p class="desc body">
    I am a
    <a class="internalLink" routerLink="/resources/thoughts-that-wander">digital craftsman</a>
    & often recognized for my commitment, analytical understanding, creativity and ability to be organized with
    particularly attention to details. I am also a software enthusiast and I love to create web applications and explore
    the idea of limitless innovation through coding.
  </p>
  <p class="body contact-me">
    <span class="text">You can find me on </span>
    <a href="https://www.linkedin.com/in/prajeet-shrestha-4a767316a/" target="_blank" class="link linkedin">Linkedin</a>
    <span class="text"> and </span>
    <a href="mailto:prajeet.shrestha.biz@gmail.com" class="link mail"> Mail</a>
  </p>
  <div class="project-section">
    <h2>Crafts</h2>

    <p class="body project-item">
      <a href="https://github.com/Akriveia-Healthtech/primate-web" target="_blank" class="link project-title"
        >Primate</a
      >
      <span class="desc"
        >- An online platform that provides publishing, analytics, design and search infrastructure to providers of
        health information.</span
      >
    </p>
    <p class="body project-item">
      <a href="https://github.com/Prajeet-Shrestha/nora.ai.the.banking.bot" target="_blank" class="link project-title"
        >Nora.ai</a
      >
      <span class="desc"
        >- Web based application intended to run on the desktop environment which lets the user interact with the
        computer as a form of chat. It will be using a model that gets trained with the user's banking data using
        Natural Language Processing.
      </span>
    </p>
    <p class="body project-item">
      <a href="http://colorpicker.prajeetshrestha.com.np/" target="_blank" class="link project-title">Color Picker</a>
      <span class="desc"
        >- Simple dynamic color picker using cursor moment on canvas that gives the hex color code. Let me know if you
        wanna checkout source code.
      </span>
    </p>
    <p class="body project-item">
      <a href="https://kuoom.prajeetshrestha.com.np/" target="_blank" class="link project-title">KUoom</a>
      <span class="desc"
        >- A platform created to make the process of finding the room more easier. Especially for the students of
        Kathmandu University as it acts as a bridge between students and house-owners to find the best room around the
        university area.
      </span>
    </p>

    <a (click)="seemore('Projects')" class="seeMore">
      <span> View all </span>

      <span class="moreIcon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.49992 4.16687V5.83354H12.9916L3.33325 15.4919L4.50825 16.6669L14.1666 7.00854V12.5002H15.8333V4.16687H7.49992Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
    </a>
  </div>
  <div class="post">
    <h2>Posts</h2>
    <div class="skeleton" *ngIf="isLoading">
      <div style="width: 90%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 95%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 70%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 60%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
    </div>
    <div class="loaded-content" *ngIf="!isLoading">
      <p *ngFor="let data of writingLists" class="body post-item">
        <span (click)="toWriting(data.id)" class="title"> {{ data.title }} </span>
        <!-- <span class="year"> {{ data.year }} </span> -->
      </p>
    </div>

    <a (click)="seemore('Posts')" class="seeMore">
      <span> View all </span>

      <span class="moreIcon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.49992 4.16687V5.83354H12.9916L3.33325 15.4919L4.50825 16.6669L14.1666 7.00854V12.5002H15.8333V4.16687H7.49992Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
    </a>
  </div>
  <div class="experiences">
    <h2>Experience</h2>
    <div class="experience-item">
      <p class="desc">Software Engineer , iBriz.ai - 10/2021 to Present</p>
      <p class="body">
        As a Software Engineer at iBriz LLC since October 2021, I've been immersed in a crafting and researching web3 projects,
        integrating blockchain contracts and game development. I had opportunities to contribute to a web3 virtual city using Phaser 3 for community
        events, and played a pivotal role in crafting game mechanics and a simulation engine for web3 games on ICON. My
        work also involved developing internal tools for project performance analysis and exploring map generative
        algorithms.
      </p>
    </div>
    <div class="experience-item">
      <p class="desc">Full stack AWS developer, Akriveia Healthtech - Contract timeline: 05/2021 to 09/2021</p>
      <p class="body">
        Developed a health information search platform and search engine using CloudSearch & internal utility functions.
        Created APIs of various range of functionality using serverless nodeJS lambda functions & developed the
        front-end design under the guidelines of a design system using Angular.
      </p>
    </div>
    <div class="experience-item">
      <p class="desc">
        Front end developer,
        <a href="https://chaitanyadesign.com/" target="_blank">Chaitanya Design</a>
        - Contract timeline: 11/2020 to 06/2021
      </p>
      <p class="body">
        Created solutions for front-end architecture and mdesign modules under the guidelines of the design team using
        React js. Worked alongside beck-end developers and integrated Rest-full APIs into the system.
      </p>
    </div>
    <div class="experience-item">
      <p class="desc">
        Angular development lead,
        <a href="https://www.nsdevil.com/" target="_blank">NSDevil</a> - Contract timeline: 09/2020 to 11/2021
      </p>
      <p class="body">
        Worked as a contract project developer with a team of Korean developers and created a front-end architecture
        using Steno Selvy UI guideline for an administrative platform that tracks, records, and assess the different
        type of users and data.
      </p>
    </div>

    <a
      href="https://prajeetshrestha.com.np/assets/Portfolio/Prajeet_Shrestha_CV_2023.pdf"
      target="_blank"
      class="seeMore"
    >
      <span> View resume </span>
      <span class="moreIcon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.49992 4.16687V5.83354H12.9916L3.33325 15.4919L4.50825 16.6669L14.1666 7.00854V12.5002H15.8333V4.16687H7.49992Z"
            fill="black"
            fill-opacity="0.6"
          />
        </svg>
      </span>
    </a>
  </div>
</div>
