import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {
  constructor() {}
  @Input() image = false;
  @Input() title = false;
  @Input() description = false;
  @Input() box = false;
  @Input() paragraph = false;

  ngOnInit(): void {}
}
