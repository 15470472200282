<div class="home-container">
  <div class="project-section">
    <h2>Projects</h2>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">YM Imagineers</a>
        <span class="desc"
          >- At Young Minds Imagineers, we blend creativity, technology, and expertise to deliver outstanding results.
          Our diverse range of services allows us to bring your vision to life, whether it's in the realms of
          architecture, film, gaming, conservation, or digital marketing.
        </span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a class="projectCTAs git">Github</a>
        <a href="https://www.ymimagineers.com/" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Punker Shooter</a>
        <span class="desc">- Punker is a shooting game demo built with Phaser 3. </span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a href="https://github.com/Prajeet-Shrestha/punker-client" target="_blank" class="projectCTAs git active"
          >Github</a
        >
        <a href="http://punker-client.vercel.app" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Geo G-Search</a>
        <span class="desc"
          >- Geo G-Search is a extended search engine results page(SERP) backed by google search APIs that primarily
          focus on providing results that are from the selected country for relevant resources.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a class="projectCTAs git">Github</a>
        <a href="https://geo-gsearch.vercel.app/" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Primate</a>
        <span class="desc"
          >- An online platform that provides publishing, analytics, design and search infrastructure to providers of
          health information.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a href="https://github.com/Akriveia-Healthtech" target="_blank" class="projectCTAs git active">Github</a>
        <a href="https://primate.health/" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Nora.ai</a>
        <span class="desc"
          >- Web based application intended to run on the desktop environment which lets the user interact with the
          computer as a form of chat. It will be using a model that gets trained with the user's banking data using
          Natural Language Processing.
        </span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>

        <a
          href="https://github.com/Prajeet-Shrestha/nora.ai.the.banking.bot"
          target="_blank"
          class="projectCTAs git active"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
        <!-- <a class="projectCTAs state active">Personal</a> -->
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">My Parikshya</a>
        <span class="desc"
          >- By Chaitanya Design Pvt. Ptd., My Parikshya is a team of youths working to help students and learners by
          providing them an easy solution to all of their entrance preparation in a single package.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a class="projectCTAs git">Github</a>
        <a href="https://www.myparikshya.com/" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Color Picker</a>
        <span class="desc"
          >- Simple dynamic color picker using cursor moment on canvas that gives the hex color code. Let me know if you
          wanna checkout source code.
        </span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a class="projectCTAs git">Github</a>
        <a href="http://colorpicker.prajeetshrestha.com.np/" target="_blank" class="web projectCTAs active">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Functional Mp3 Player For Webflow</a>
        <span class="desc"
          >- code for those who are facing to plugin audio player in your webflow website development.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          class="projectCTAs git active"
          href="https://github.com/Prajeet-Shrestha/Mp3Player-For-WebFlow-CMS-Dynamic-audioLinks"
          target="_blank"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">KUoom</a>
        <span class="desc"
          >- A platform created to make the process of finding the room more easier. Especially for the students of
          Kathmandu University as it acts as a bridge between students and house-owners to find the best room around the
          university area.
        </span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a class="projectCTAs git">Github</a>
        <a href="https://kuoom.prajeetshrestha.com.np/" target="_blank" class="active web projectCTAs">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Kerberos authentication system simulation</a>
        <span class="desc">- Simulation of how Kerberos system work using logs and some functionalities.</span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          href="https://github.com/Prajeet-Shrestha/kerberos-authentication-system"
          target="_blank"
          class="projectCTAs git active"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Fare Optimization Webapp</a>
        <span class="desc"
          >- A project for implementing Dijkstra's algorithm to find the cheapest and shortest route inside Kathmandu
          Valley with UI for better usability.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          class="projectCTAs git active"
          href="https://github.com/Prajeet-Shrestha/Transportation-fare-optimization"
          target="_blank"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>

    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">UI Designing for Carla Simulation</a>
        <span class="desc"
          >- A team research project for development of UI that enhances the simulation's usability.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          class="projectCTAs git active"
          href="https://github.com/Prajeet-Shrestha/Smart-UI-for-Carla-Simulation"
          target="_blank"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">Development of Carla Simulation Controller UI using HCI Principles | Vodcast</a>
        <span class="desc"
          >- Video podcast that was made to document the research project we did to design a Controller UI.</span
        >
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          class="projectCTAs git active"
          href="https://github.com/Prajeet-Shrestha/Development-of-Carla-Simulation-Controller-UI-using-HCI-Principles-Video-Podcast"
          target="_blank"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>
    <div class="projectCard">
      <p class="body project-item">
        <a class="link project-title">8 Golden Rules Of Interface Design</a>
        <span class="desc">- video project that was made to describe the 8 Golden Rules of UserInterface Design.</span>
      </p>
      <div class="CTAs">
        <a class="projectCTAs state">Completed</a>
        <a
          class="projectCTAs git active"
          href="https://github.com/Prajeet-Shrestha/8-Golden-Rules-Video-project"
          target="_blank"
          >Github</a
        >
        <a class="web projectCTAs">Website</a>
      </div>
    </div>
  </div>
</div>
