import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import checkTime from '../app/services/checkTime';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portfolio-prajeet';
  darkMode = false;
  menuState = {
    About: true,
    Projects: false,
    Posts: false,
    Resume: false,
  };

  mMenu = {
    isOpen: false,
  };
  togglecolor: boolean = false;

  constructor(private _router: Router) {}

  @ViewChild('About') AboutElement;
  @ViewChild('Projects') ProjectsElement;
  @ViewChild('Posts') PostsElement;
  @ViewChild('Resume') ResumeElement;

  toggleMobileMenu() {
    this.mMenu.isOpen = this.mMenu.isOpen ? false : true;
  }
  mouseEnter() {
    if (!this.darkMode) {
      this.togglecolor = true;
    } else {
      this.togglecolor = false;
    }
  }
  toggleDarkMode() {
    document.getElementById('dm-mode').classList.toggle('active');
    if (document.getElementsByTagName('body')[0].classList.value == 'dark-mode') {
      setTimeout(function (params) {
        document.getElementsByTagName('body')[0].classList.toggle('dark-mode');
      }, 900);
    } else {
      document.getElementsByTagName('body')[0].classList.toggle('dark-mode');
    }
    this.darkMode = this.darkMode ? false : true;
  }
  mouseLeave() {
    if (!this.darkMode) {
      this.togglecolor = false;
    } else {
      this.togglecolor = true;
    }
  }
  routeTo(page) {
    console.log('hey');
    switch (page) {
      case 'About':
        this._router.navigate(['']);
        this.menuState = {
          About: true,
          Projects: false,
          Posts: false,
          Resume: false,
        };
        this.mMenu.isOpen = false;
        this.toggleMenu('About');
        break;
      case 'Posts':
        this.mMenu.isOpen = false;
        this._router.navigate(['/resources']);
        this.menuState = {
          About: false,
          Projects: true,
          Posts: false,
          Resume: false,
        };
        this.toggleMenu('Posts');
        break;
      case 'Projects':
        this.mMenu.isOpen = false;
        this._router.navigate(['/projects']);
        this.menuState = {
          About: false,
          Projects: false,
          Posts: true,
          Resume: false,
        };
        this.toggleMenu('Projects');
        break;
      case 'Resume':
        this.mMenu.isOpen = false;
        // this._router.navigate(['/connect']);
        window.open('https://prajeetshrestha.com.np/assets/Portfolio/Prajeet_CV_2023.pdf', '_blank');
        this.menuState = {
          About: false,
          Projects: false,
          Posts: false,
          Resume: true,
        };
        this.toggleMenu('Resume');
        break;
    }
  }
  hideProfile = false;
  ngAfterViewInit() {
    // let url = window.location.href.split('/');
    // console.log(url);
    // console.log(checkTime());
    if (checkTime() == 'DARK') {
      this.toggleDarkMode();
    }
    this._router.events.subscribe((res) => {
      let url = res['url'];
      if (url !== undefined) {
        if (url.includes('resources')) {
          this.toggleMenu('Posts');
          if (url.includes('archive')) {
            this.hideProfile = true;
          } else {
            this.hideProfile = false;
          }
        } else if (url.includes('connect')) {
          this.toggleMenu('Resume');
          this.hideProfile = false;
        } else if (url.includes('projects')) {
          this.toggleMenu('Projects');
          this.hideProfile = false;
        } else {
          this.hideProfile = false;
          this.toggleMenu('About');
        }
      }
    });
    // this._router.
  }

  toggleMenu(menu: 'About' | 'Projects' | 'Posts' | 'Resume') {
    switch (menu) {
      case 'Projects':
        this.AboutElement.nativeElement.classList.remove('active');
        this.ProjectsElement.nativeElement.classList.add('active');
        this.PostsElement.nativeElement.classList.remove('active');
        this.ResumeElement.nativeElement.classList.remove('active');
        break;
      case 'Posts':
        this.AboutElement.nativeElement.classList.remove('active');
        this.ProjectsElement.nativeElement.classList.remove('active');
        this.PostsElement.nativeElement.classList.add('active');
        this.ResumeElement.nativeElement.classList.remove('active');
        break;
      // case 'Resume':
      //   this.AboutElement.nativeElement.classList.remove('active');
      //   this.ProjectsElement.nativeElement.classList.remove('active');
      //   this.PostsElement.nativeElement.classList.remove('active');
      //   this.ResumeElement.nativeElement.classList.add('active');
      //   break;
      case 'About':
        this.AboutElement.nativeElement.classList.add('active');
        this.ProjectsElement.nativeElement.classList.remove('active');
        this.PostsElement.nativeElement.classList.remove('active');
        this.ResumeElement.nativeElement.classList.remove('active');
        break;
    }
  }
}
