const checkTime = () => {
  var today = new Date();
  var curHr = today.getHours();
  if (curHr <= 7) {
    return 'DARK'.toUpperCase();
  } else if (curHr >= 8 && curHr <= 18) {
    return 'LIGHT'.toUpperCase();
  } else {
    return 'DARK'.toUpperCase();
  }
};

export default checkTime;
