import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

var artList = [
  `
  🏿🏿🏿🏿🏿🏿🏿🏿
🏿🏿🏿🏿🏿🏿🏿🏿
🏿🏿🏽🏽🏽🏽🏿🏿
🏽🏽🏽🏽🏽🏽🏽🏽
🏽⬜⬛🏽🏽⬛⬜🏽
🏽🏽🏽🏿🏿🏽🏽🏽
🏽🏽🏿🏽🏽🏿🏽🏽
🏽🏽🏿🏿🏿🏿🏽🏽
  `,
  `──────────────────▒
─────────────────░█
────────────────███
───────────────██ღ█
──────────────██ღ▒█──────▒█
─────────────██ღ░▒█───────██
─────────────█ღ░░ღ█──────█ღ▒█
────────────█▒ღ░▒ღ░█───██░ღღ█
───────────░█ღ▒░░▒ღ░████ღღღ█
───░───────█▒ღ▒░░░▒ღღღ░ღღღ██─────░█
───▓█─────░█ღ▒░░░░░░░▒░ღღ██─────▓█░
───██─────█▒ღ░░░░░░░░░░ღ█────▓▓██
───██────██ღ▒░░░░░░░░░ღ██─░██ღ▒█
──██ღ█──██ღ░▒░░░░░░░░░░ღ▓██▒ღღ█
──█ღღ▓██▓ღ░░░▒░░░░░░░░▒░ღღღ░░▓█
─██ღ▒▒ღღ░░ღღღღ░░▒░░░░ ღღღღ░░ღღღ██
─█ღ▒ღღ█████████ღღ▒░ღ██████████ღ▒█░
██ღღ▒████████████ღღ████████████░ღ█▒
█░ღღ████████████████████████████ღღ█
█▒ღ██████████████████████████████ღ█
██ღღ████████████████████████████ღ██
─██ღღ██████████████████████████ღ██
──░██ღღ██████████████████████ღღ██
────▓██ღ▒██████████████████▒ღ██
───░──░███ღ▒████████████▒ღ███
────░░───▒██ღღ████████▒ღ██
───────────▒██ღ██████ღ██
─────────────██ღ████ღ█
───────────────█ღ██ღ█
────────────────█ღღ█
────────────────█ღ█░
─────────────────██░`,
  `____¶¶¶¶_______________________________¶¶¶¶
__¶¶¶11¶¶¶___________________________¶¶¶11¶¶¶
_¶¶111111¶¶_________________________¶¶111111¶¶
_¶¶111111¶¶_________________________¶¶111111¶¶
__¶¶¶111¶¶___________________________¶¶1111¶¶
____¶¶¶11¶¶__________________________¶¶11¶¶¶
______¶¶11¶¶_______¶¶¶¶¶¶¶¶¶________¶¶11¶¶
_______¶¶11¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶11¶¶
________¶¶11¶¶11111111111111111111¶¶11¶¶
______¶¶¶11111111111111111111111111111¶¶¶
____¶¶¶1111111111111111111111111111111111¶¶
___¶¶11111111111111111111111111111111111111¶¶
__¶11111111111111111111111111111111111111111¶¶
_¶1111111111111111111111111111111111111111111¶¶
¶¶1111111111111111111111111111111111111111111¶¶
¶¶11111111111111111111111111111111111111111111¶
_¶11111111111¶¶¶¶¶11111111111¶¶¶¶¶11111111111¶¶
_¶¶11111111111111111111111111111111111111111¶¶
__¶¶¶11111111¶¶¶¶¶¶¶¶1111111¶¶¶¶¶¶¶¶1111111¶¶
____¶¶11111¶¶¶¶¶¶¶_¶¶¶1111¶¶¶¶¶¶¶__¶¶1111¶¶¶
_____¶¶111¶¶¶¶¶¶¶____¶¶¶¶¶¶¶¶¶¶¶¶___¶¶11¶¶
_______¶11¶¶¶¶¶¶¶¶¶_¶¶¶11¶¶¶¶¶¶¶¶¶_¶¶¶1¶¶
______¶¶111¶¶¶¶¶¶¶¶¶¶¶1111¶¶¶¶¶¶¶¶¶¶¶111¶
_____¶¶11111¶¶¶¶¶¶¶¶11111111¶¶¶¶¶¶¶111111¶
_____¶1111111111111111¶¶1¶111111111111111¶
_____¶¶111111111111111¶111¶1111111111111¶¶
______¶¶1111111111111111111111111111111¶¶
_______¶¶11111111111111111111111111111¶¶
_________¶¶¶11111111¶¶¶¶¶¶¶¶¶1111111¶¶¶
___________¶¶¶¶1111111¶¶¶¶¶11111¶¶¶¶
______________¶¶¶¶1111111111111¶¶¶
_________________¶¶11111111111¶¶
__________________¶¶¶¶¶¶¶¶¶¶¶¶¶
`,
  `┼┼┼┼┼┼┼▄▀▀▀▄▄▄▄▄▄▄▀▀▀▄┼┼┼┼┼┼
┼┼┼┼┼┼┼█▒▒░░░░░░░░░▒▒█┼┼┼┼┼┼
┼┼┼┼┼┼┼┼█░░█░░░░░█░░█┼┼┼┼┼┼┼
┼┼┼┼─▄▄──█░░░▀█▀░░░█──▄▄─┼┼┼
┼┼┼┼█░░█─▀▄░░░░░░░▄▀─█░░█┼┼┼
┼██░██░████░██░░░██░░░█████┼
┼██▄██░██▄▄░██░░░██░░░██░██┼
┼██▀██░██▀▀░██░░░██░░░██░██┼
┼██░██░████░████░████░█████┼`,
  `───▄▀▀▀▄▄▄▄▄▄▄▀▀▀▄───
───█▒▒░░░░░░░░░▒▒█───
────█░░█░░░░░█░░█────
─▄▄──█░░░▀█▀░░░█──▄▄─
█░░█─▀▄░░░░░░░▄▀─█░░█`,
  `-------------------------
░░░░░███████ ]▄▄▄▄▄▄▄▄
▂▄▅█████████▅▄▃▂         ☻
Il███████████████████  / ▌\╦─  
@@@@@@@@@@@@@@         /  \              `,
  `

＼＼ ＿
　　 ＼(　•_•) F
　　　 <　⌒ヽ A
　　　/ 　 へ＼ B
　　 /　　/　＼＼ U
　　 |　ノ　　 ヽ_つ L
　　/　/ O
　 /　/| U
　(　(ヽ S
　|　|、＼
　| 丿 ＼ ⌒)
　| |　　) /
ノ )　　Lﾉ
`,
  `∵*.•´¸.•*´✶´♡
° ☆ ° ˛*˛☆_Π______˚☆
*˚ ˛★˛•*/________/ ~ ⧹。˚ ˚
˚ ˛•˛•˚  ｜ 田田 ｜門｜ ˚
🌷╬╬🌷╬╬🌷╬╬🌷╬╬🌷
`,
];

if (environment.production) {
  enableProdMode();
  // console.log(artList[Math.floor(Math.random() * artList.length)]);
  // console.log = function () {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
