import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../services/metaInfo';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('Prajeet Shrestha | Projects');
    console.log(metaInfo.projectsOG);
    metaInfo.projectsOG.map((data) => {
      this.metaService.updateTag(data);
    });
  }

  ngOnInit(): void {}
}
