<div class="res-container">
  <h2>Thoughts that wander around</h2>
  <div class="writing-content">
    <h4>I am a digital craftsman.&nbsp;</h4>

    <blockquote>
      <p><em>Oct 30, 2021</em></p>

      <p>
        I am a digital craftsman and I take pride in it. When you think about a
        craftsman, you might imagine an old man in his 70s' with a smoking pipe,
        slowly chipping away woods with love, passion, and care to make
        something. You are not alone in this, I also used to imagine the same
        way that you are now. But I came to the realization that when they do
        and what we do as a developer do are not so different, in the fact that
        I love what I create and understand the difference between the easy way
        and the right way to learn through the process with passion and
        determination.
      </p>
    </blockquote>
    <h4>Opening the door for you</h4>

    <blockquote>
      <p><em>Sept 18, 2021</em></p>

      <p>
        When I am feeling low and weak, I usually shut myself out, so I
        don&#39;t get hurt. And keeping the door open is really hard. So I get
        angry, agitated, and irritated for no reason. Because i want you in.
        Because i need you with me and I fight myself to do that. Sometimes, all
        I need is ... a friend.&nbsp;
      </p>
    </blockquote>
    <h4>Letter to my Successor</h4>

    <blockquote>
      <p><em>AIESEC, 2021</em></p>

      <p>
        Hey, my man! I couldn't have been much proud to see you up there
        delivering your heart and soul for this LC that you grew up in. I can
        tell you that I feel you to every bone. But also know that this will
        make you stronger, this was just a milestone in your journey and not the
        destination. Someday along your journey, when you look back you will
        laugh and think of those good days. Anyways, feels good to see you
        progressing. You have grown a lot since I met you. Let's meet after this
        lock-down and talk over Kafle sweets ko chowmin, samosa, and jalebi.
      </p>
    </blockquote>
    <h4>Mourning the death of a stranger&nbsp;</h4>

    <blockquote>
      <p><em>Jun 3, 2020</em></p>

      <p>
        A friend of mine shared how she deeply felt bad from the death of this
        elephant in Kerala and how she mourns for her. She felt that her sadness
        and mourning for life of an innocent being is not making a difference. I
        hope she realize that tears; she sheds had love. It had hope, That will
        never be understood by the people whose minds and hearts are corrupted
        by greed and power. But the world understands it; but the nature
        understands her. I promptly said to her that you are mourning the death
        of innocent life. There is nothing noble than that.Never, feel that it
        won't make a difference. For your actions always has its consequences.
        For it has given you love and hope for this world.
      </p>
    </blockquote>

    <p>&nbsp;</p>

    <h4>&nbsp;</h4>
  </div>
</div>
