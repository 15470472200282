import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

export interface writingListTemplate {
  id: string;
  year: string;
  slug: string;
  title: string;
  body: string;
}

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  test = false;
  url_test = 'http://localhost:3000';
  url_prod = 'https://portfolio-prajeet-api.herokuapp.com';
  currentModeURL = '';
  constructor(private _http: HttpService) {
    this.currentModeURL = this.test ? this.url_test : this.url_prod;
  }

  getAllPosts() {
    return this._http.get(`${this.currentModeURL}/writings`);
  }

  getOnePost(id) {
    return this._http.get(`${this.currentModeURL}/getOnePost?id=${id}`);
  }
}
