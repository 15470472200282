import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PostsService } from 'src/app/services/http/posts/posts.service';
import { HttpService } from '../../services/http/http.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { metaInfo } from '../../services/metaInfo';
export interface writingListTemplate {
  id: string;
  year: string;
  slug: string;
  title: string;
  body: string;
}

@Component({
  selector: 'app-home',

  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private postService: PostsService,
    private _router: Router,
    private _http: HttpService,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('Prajeet Shrestha');
    metaInfo.homeOG.map((data) => {
      this.metaService.updateTag(data);
    });
  }
  isLoading = true;
  showMore = true;
  writingLists: writingListTemplate[] = [
    {
      id: '',
      year: '',
      slug: '',
      title: '',
      body: '',
    },
  ];
  toWriting(id) {
    // console.log('hey');
    this._router.navigate(['/resources/archive', id]);
  }

  toggleShowMore() {
    this.showMore = this.showMore ? false : true;
  }

  seemore(page: 'Posts' | 'Projects') {
    page == 'Posts' ? this._router.navigate(['/resources']) : this._router.navigate(['/projects']);
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.postService.getAllPosts().subscribe(
      (res: writingListTemplate[]) => {
        // console.log(res);
        this.writingLists = res.splice(0, 6);
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }
}
