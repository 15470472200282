<div class="writing-container">
  <div class="post">
    <h2>Posts</h2>
    <div class="timeless">
      <h4>Timeless</h4>
      <p class="post-item body PostsList">
        <a [routerLink]="['/resources/thoughts-that-wander']"> Thoughts </a>
      </p>
      <p [routerLink]="['/resources/bucket-list']" class="post-item body PostsList">
        <a [routerLink]="['/resources/bucket-list']"> My Bucket List </a>
      </p>
    </div>
    <div class="skeleton" *ngIf="isLoading">
      <div style="width: 60px">
        <br />
        <app-skeleton [title]="true"></app-skeleton>
        <br />
      </div>
      <div style="width: 90%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 95%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 70%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 60%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <div style="width: 60px">
        <br />
        <app-skeleton [title]="true"></app-skeleton>
        <br />
      </div>
      <div style="width: 90%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 95%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 70%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
      <br />
      <div style="width: 60%">
        <app-skeleton [paragraph]="true"></app-skeleton>
      </div>
    </div>
    <div class="loaded-content" *ngIf="!isLoading">
      <div *ngFor="let year of YearNumberList; let i = index" class="year-section 2021">
        <h4>{{ year }}</h4>
        <p *ngFor="let data of yearWiseList[i]" class="PostsList body post-item">
          <a
            [routerLink]="['/resources/archive', data.id]"
            (click)="toWriting(data.id, data.hyperLink !== undefined ? data.hyperLink : false)"
            class="title"
          >
            {{ data.title }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
