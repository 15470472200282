<div class="res-container">
  <h2>My list of 100 things</h2>
  <p>
    There are so many things I want to do before I lay on my death bed and feel the sense of fulfillment. Let me know,
    if you have any recommendation. ❤ (last updated 2022)
  </p>
  <ol>
    <li>Publish a book</li>
    <li>✓ Become a software engineer</li>
    <li>Publish an NPM package</li>
    <li>✓ Learn coding</li>
    <li>✓ Complete under-graduation</li>
    <li>✓ Propose someone</li>
    <li>Visit Russia</li>
    <li>Visit Paris</li>
    <li>✓ Get underpaid</li>
    <li>✓ Strip in public (not fully though)</li>
    <li>Get married to someone i love</li>
    <li>See Taj Mahal</li>
    <li>✓ Dance in public</li>
    <li>✓ Fall in love</li>
    <li>✓ Ask a stranger out (failed awkwardly)</li>
    <li>✓ Kiss in public</li>
    <li>Develop an application which become popular</li>
    <li>Live outside the country</li>
    <li>~ Be Humble</li>
    <li>~ Be Kind</li>
    <li>Start a company</li>
    <li>Read 1000 books</li>
    <li>Build a home</li>
    <li>✓ Get rejected by someone</li>
    <li>✓ Get Proposed by someone</li>
    <li>Become a parent</li>
    <li>Grow old</li>
    <li>Sky dive</li>
    <li>Learn Swimming</li>
    <li>Scuba dive</li>
    <li>Become a millionaire</li>
    <li>✓ Go Hiking</li>
    <li>✓ Become a teacher</li>
    <li>✓ Lead people</li>
    <li>✓ Learn to drive cars & bikes</li>
    <li>Be a part of a flash mob</li>
    <li>Go camping</li>
    <li>
      ✓ Become a shiva in
      <a href="https://en.wikipedia.org/wiki/Gai_Jatra" target="_blank">Gai Jatra </a>
    </li>
    <li>
      Become a
      <a href="https://en.wikipedia.org/wiki/Lakhey" target="_blank">Lakhey </a>
    </li>
    <li>Hike to Everest base camp</li>
    <li>Meet a princess</li>
    <li>Meet a king</li>
    <li>Speak at TEDx</li>
    <li>Learn to play a musical instrument (tried to learn guitar)</li>
    <li>✓ Ride a bike in the rain</li>
    <li>✓ Lay on my back on the main street</li>
    <li>~ Learn 50 Programming Languages (5%)</li>
    <li>✓ Throw the Square academic cap up in the sky (soon)</li>
    <li>Aim to work in google</li>
    <li>✓ Sneak into an uninvited party</li>
    <li>✓ Travel alone</li>
    <li>Visit strip club</li>
    <li>✓ Go to a dancing bar</li>
    <li>✓ Get kissed by a stranger</li>
    <li>~ Watch Sunset</li>
    <li>✓ Get drunk</li>
    <li>✓ Hitchhike (only few miles for now)</li>
    <li>Visit Silicon Valley</li>
    <li>✓ Drink a cocktail</li>
    <li>✓ Drink vodka until you are wasted</li>
    <li>✓ Cry for someone</li>
    <li>✓ Volunteer for a project</li>
    <li>✓ Buy a mac book</li>
    <li>Be in a movie/short film</li>
    <li>Make a wish upon a falling star</li>
    <li>✓ Do something illegal</li>
    <li>✓ Lay on a open plain and feel the wind growling</li>
    <li>Find a job you love</li>
    <li>✓ Get a gym membership</li>
    <li>~ Get financially independent</li>
  </ol>
</div>
